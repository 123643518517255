import React from "react"
import { Helmet } from "react-helmet"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import ContactForm from "components/ContactForm"

import { Content } from "page_components/financing-calc"

const Contact = ({ location }) => {
  const title = "Financing"

  return (
    <Layout
      location={location}
      seo={{
        title: "Finansowanie zakupu nieruchomości",
        description:
          "Pomożemy w uzyskaniu kredytu hipotecznego i wspólnie przejdziemy przez wszystkie formalności. Kupno mieszkania nigdy nie było tak proste!",
      }}
    >
      <Helmet>
        <script src="https://narzedzia.notus.pl/rata-kredytu-wynik-kalkulatora_api_min.js" />
      </Helmet>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <ContactForm />
    </Layout>
  )
}

export default Contact
